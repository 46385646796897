import React from 'react';
import '../css/Home.css';
import PhotoCard from '../components/PhotoCard';
import EventSummaryCard from '../components/EventSummaryCard';
import eventsData from '../Data/eventsData.json';
import westwardHoImage from '../assets/westward_ho.png';
import dartmoorRunImage from '../assets/dartmoor_run.png';
import ClubLogo from "../assets/ClubLogo_west.png";
import photo1 from '../assets/meet1.jpeg';
import photo2 from '../assets/meet2.jpeg';
import photo3 from '../assets/meet3.jpeg';

const images = {
  "westward_ho.png": westwardHoImage,
  "dartmoor_run.png": dartmoorRunImage
};

const photoGallery = {
  title: "March Section Meet at Fingle Bridge",
  description: "Andrew, Malcolm, Tony, James and bikes. We met up at the pub, for lunch, and took advatnage of the nice weather to ride our bikes there.",
  photos: [
    { image: photo1, subtitle: "" },
    { image: photo2, subtitle: "" },
    { image: photo3, subtitle: "" },
  ]
};

function Home({ onNavigate }) {
  return (
    <div className="home-content" style={{ position: 'relative' }}>
      
      {/* Faded background image - positioned behind everything */}
      <div className="image-container" style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
        pointerEvents: 'none'
      }}>
        <div className="faded-image"></div>
      </div>

      {/* Main Content Wrapper */}
      <div style={{ position: 'relative', zIndex: 1 }}>
        
        {/* Welcome Text */}
        <div className="text-content">
          <div className="header-grid">
            <p className="small-header">AJS & Matchless Owners Club</p>
            <h1 className="jampot-title">Jampot</h1>
            <p className="small-header west-of-england">Devon & Cornwall</p>
          </div>

          <h2>Welcome to the Devon & Cornwall section of the AJS & Matchless Owners Club!</h2>
          <p>
            We are a dedicated group within the broader AJS & Matchless community, focused on preserving the rich legacy of these iconic British motorcycles. Our section, spanning the beautiful regions of Devon and Cornwall, unites enthusiasts, restorers, and riders who share a deep passion for the craftsmanship and heritage of AJS & Matchless machines.
          </p>
          <p>
            Whether you’re an experienced rider or new to classic motorcycles, our section offers a welcoming space to connect with like-minded individuals. Join us in celebrating these historic motorcycles, participating in exciting events, and keeping the AJS & Matchless spirit alive.
          </p>
        </div>

   {/* Full-width Horizontal Photo Strip */}
<div className="photo-strip">
  <div className="photo-strip-header">
    <h3>{photoGallery.title}</h3>
  </div>
  <div className="photo-strip-inner">
    {photoGallery.photos.map((photo, index) => (
      <PhotoCard key={index} image={photo.image} subtitle={photo.subtitle} />
    ))}
  </div>
  <div className="photo-strip-header">
    <p>{photoGallery.description}</p>
  </div>
</div>

        {/* Events */}
        <div className="text-content">
          <h3>Upcoming Events</h3>
          <div className="events-summary-list">
            {eventsData
              .filter(event => event.title)
              .map((event, index) => (
                <EventSummaryCard 
                  key={index}
                  isImageOnly={event.isImageOnly}
                  title={event.title}
                  mapQuery={event.mapQuery}
                  date={event.date}
                  image={images[event.image] || ClubLogo}
                  link="/events"
                />
            ))}
          </div>
        </div>

      </div>
    </div>
  );
}

export default Home;