import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaMapMarkerAlt } from 'react-icons/fa';
import eventsData from '../Data/eventsData.json';
import westwardHoImage from '../assets/westward_ho.png';
import dartmoorRunImage from '../assets/dartmoor_run.png';
import ClubLogo from "../assets/ClubLogo_west.png";

const images = {
  "westward_ho.png": westwardHoImage,
  "dartmoor_run.png": dartmoorRunImage
};

const EventCard = ({ title, date, location, description, mapQuery, image, isImageOnly, additionalText, citation }) => {
  return (
    <div 
      className="card position-relative" 
      style={{
        backgroundColor: 'white',
        maxWidth: '500px',
        margin: '10px',
        width: '100%',
        textAlign: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: isImageOnly ? '0' : '20px',
        marginBottom: isImageOnly ? '0' : '20px',
        border: 'none',
        position: 'relative',
      }}
    >
      {isImageOnly ? (
        <>
          <img 
            src={images[image]} 
            alt="Event" 
            style={{ width: '100%', height: 'auto', display: 'block', borderRadius: '0' }}
          />
          {additionalText && (
            <div style={{ padding: '20px', textAlign: 'left' }}>
              <p>{additionalText}</p>
              {citation && (
                <p style={{ fontWeight: 'bold', fontStyle: 'italic', marginTop: '10px' }}>{citation}</p>
              )}
            </div>
          )}
        </>
      ) : (
        <div>
          <img 
            src={ClubLogo} 
            alt="Club Badge" 
            style={{
              width: '60px', 
              height: 'auto', 
              position: 'absolute', 
              top: '35px', 
              left: '35px'
            }} 
          />

          <div style={{ marginTop: '20px' }}>
            <h1 className="text-xl font-bold mx-auto" style={{ textAlign: 'center', maxWidth: '80%', wordWrap: 'break-word' }}>
              {title}
            </h1>
            <p className="text-xl font-bold mx-auto" style={{ textAlign: 'center', maxWidth: '50%', wordWrap: 'break-word' }}>
              <strong>Date:</strong> {date}
            </p>
            <p className="text-gray-700 d-flex align-items-center justify-content-center">
              <FaMapMarkerAlt className="text-primary me-2" /> 
              <strong>Location: </strong> {location}
            </p>
            <p className="text-gray-600">{description}</p>

            {/* Embedded Google Map */}
            <div className="mt-4">
              <iframe
                title={`${title} Map`}
                width="100%"
                height="250"
                className="rounded-lg border-0"
                loading="lazy"
                allowFullScreen
                src={`https://www.google.com/maps?q=${mapQuery}&output=embed`}
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function Events() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '100px 20px 20px',
      backgroundColor: 'transparent',
      width: '100%',
    }}>
      <div className="image-container2">
        <div className="faded-RallyImage"></div>
      </div>

      {eventsData.map((event, index) => (
        <EventCard key={index} {...event} />
      ))}
    </div>
  );
}

export default Events;