import React, { useState } from 'react';

function PhotoCard({ image, subtitle }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const styles = {
    card: {
      margin: '1rem',
      textAlign: 'center',
      maxWidth: '300px',
      cursor: 'pointer',
    },
    image: {
      width: '100%',
      height: 'auto',
      borderRadius: '8px',
      transition: 'transform 0.2s ease',
    },
    subtitle: {
      marginTop: '0.5rem',
      fontSize: '1rem',
      color: '#333',
    },
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9999,
    },
    modalImage: {
      maxWidth: '90%',
      maxHeight: '90%',
      borderRadius: '12px',
      boxShadow: '0 0 15px rgba(0,0,0,0.4)',
    },
    closeButton: {
      position: 'absolute',
      top: '20px',
      right: '30px',
      fontSize: '2rem',
      color: '#fff',
      cursor: 'pointer',
      background: 'none',
      border: 'none',
    }
  };

  return (
    <>
      <div style={styles.card} onClick={() => setIsModalOpen(true)}>
        <img src={image} alt={subtitle} style={styles.image} />
        <p style={styles.subtitle}>{subtitle}</p>
      </div>

      {isModalOpen && (
        <div style={styles.modalOverlay} onClick={() => setIsModalOpen(false)}>
          <button
            style={styles.closeButton}
            onClick={(e) => {
              e.stopPropagation();
              setIsModalOpen(false);
            }}
          >
            &times;
          </button>
          <img src={image} alt={subtitle} style={styles.modalImage} />
        </div>
      )}
    </>
  );
}

export default PhotoCard;