import React from 'react';
import { Link } from 'react-router-dom';

const EventSummaryCard = ({ title, date, image, isImageOnly, mapQuery }) => {
  return (
    <div 
      className="d-flex align-items-center event-summary-card"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#fff',
        padding: '10px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        marginBottom: '10px',
        width: '100%',
        maxWidth: '400px',
        textDecoration: 'none'
      }}
    >
      {/* Conditional Display: Event Image or Tiny Map */}
      {isImageOnly ? (
        <img 
          src={image} 
          alt={title} 
          style={{ 
            width: '60px', 
            height: '60px', 
            borderRadius: '8px', 
            objectFit: 'cover' 
          }} 
        />
      ) : (
        <img 
        src={image} 
        alt={title} 
        style={{ 
          width: '60px', 
          height: '60px', 
          borderRadius: '8px', 
          objectFit: 'cover' 
        }} 
      />
      )}

      {/* Event Details */}
      <div 
        style={{ 
          flex: 1, 
          marginLeft: '10px', 
          fontSize: '16px', 
          fontWeight: 'bold',
          textAlign: 'left'
        }}
      >
        {title}
        <div style={{ fontSize: '14px', color: '#555', marginTop: '2px' }}>
          {date}
        </div>
      </div>
    </div>
  );
};

export default EventSummaryCard;